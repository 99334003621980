<!-- 邀请注册 -->
<template>
  <div class="frame">
    <div v-if="!from" class="frame-title">
      <span>欢迎注册</span>
      <span style="float: right">中国家居产业智联网</span>
    </div>
    <div v-if="!from">
      <ItemStep></ItemStep>
    </div>
    <div v-if="this.from !== 'contact'" class="reg_success">
      <img src="../../assets/images/login/dggfg@2x.png" alt="" />
      <div>恭喜您！注册成功</div>
    </div>
    <div v-if="$route.query.type" style="padding-top: 80px; text-align: center">
      <el-button
        @click="goto('/center')"
        style="width: 105px"
        size="small"
        type="primary"
        >进入工作台</el-button
      >
    </div>
    <div v-else style="margin: 20px auto" class="reg-frm">
      <div>
        <div style="margin-top: 0px">
          <!-- <div style="text-align: center;font-size:22px;margin-bottom:10px;" class="form-title">注册成功</div> -->
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-form-item style="text-align: center">
              <span
                class="form-title"
                style="font-size: 16px; font-weight: bold; color: #fff"
                >您还可以，邀请更多同事线上使用</span
              >
            </el-form-item>
            <el-form-item
              v-for="(Invite, index) in ruleForm.Invites"
              :key="Invite.key"
              :prop="'Invites.' + index + '.value'"
              :rules="rules.phone"
            >
              <el-col :span="24" class="flex" style="position: relative">
                <el-input
                  v-model="Invite.value"
                  :maxlength="11"
                  placeholder="请输入手机号码"
                ></el-input>
                <div style="position: absolute; top: 0; right: -60px">
                  <el-button
                    v-if="index > 0"
                    @click.prevent="removeInvite(Invite)"
                    icon="el-icon-delete"
                  ></el-button>
                </div>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-button
                @click="addInvite"
                style="width: 100%"
                type="primary"
                plain
                round
              >
                <a-icon style="margin-right: 6px" type="plus" /><span
                  >继续邀请</span
                >
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-button
                @click="confirm('ruleForm')"
                style="width: 100%"
                type="primary"
                round
                >确认邀请</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-col :span="24" class="Invite-frm">
                <div class="flex">
                  <div>邀请码：</div>
                  <div>{{ InviteData.invite_code }}</div>
                </div>
                <div class="flex">
                  <div>机构号：</div>
                  <div>{{ InviteData.comp_code }}</div>
                </div>
                <div class="flex">
                  <div>过期时间：</div>
                  <div>{{ InviteData.expired_at | moment("YYYY-MM-DD") }}</div>
                </div>
              </el-col>
            </el-form-item>
            <el-form-item
              v-if="this.from !== 'contact'"
              style="text-align: center"
            >
              <el-button size="small" type="primary" plain @click="gotoWhere()"
                >跳过</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { genInviteCode, sendInviteCode } from "@/service/account";
import { phoneReg } from "@/utils";
import ItemStep from "./ItemStep.vue";
export default {
  props: {},
  components: {
    ItemStep,
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号"));
      } else {
        const reg = phoneReg;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      ruleForm: {
        Invites: [
          {
            value: "",
          },
        ],
      },
      rules: {
        phone: [{ validator: checkPhone, trigger: "blur" }],
      },
      InviteData: {
        comp_code: null,
        invite_code: null,
        expired_at: null,
      },
      from: null,
    };
  },
  created() {
    this.$store.state.regStep = 4;
  },
  mounted() {
    genInviteCode().then((resp) => {
      this.InviteData = resp;
    });
    this.from = this.$route.query.from;
  },
  methods: {
    goto(path) {
      this.$router.push(path);
    },
    confirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let phones = this.ruleForm.Invites.reduce((pre, cur) => {
            pre.push(cur.value);
            return pre;
          }, []).join();
          console.log(phones);
          sendInviteCode(this.InviteData.invite_code, phones)
            .then(() => {
              this.$message.success("邀请成功，已通过短信方式通知受邀人");
              this.gotoWhere();
            })
            .catch((err) => {
              console.log(err);
              this.$message.error(err.message);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    gotoWhere() {
      if (this.from == "contact") {
        this.$router.push("/comp/contact");
      } else {
        this.$router.push("/comp/base");
      }
    },
    removeInvite(item) {
      var index = this.ruleForm.Invites.indexOf(item);
      if (index !== -1) {
        this.ruleForm.Invites.splice(index, 1);
      }
    },
    addInvite() {
      this.ruleForm.Invites.push({
        value: "",
        key: Date.now(),
      });
    },
  },
};
</script>
<style scoped >
:deep(.el-input__inner) {
  background-color: transparent;
}
:deep(.el-button--primary.is-plain) {
  background-color: transparent;
}
.frame {
  color: #fff;
  background: #0e1423;
  height: 100%;
  overflow: auto;
  padding-top: 60px;
}

.frame-title {
  font-size: 24px;
  font-weight: bold;
  width: 330px;
  margin: 45px auto 30px;
  overflow: hidden;
  color: #fff;
}
.reg_success {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}
.reg_success > img {
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
}

.reg-frm {
  margin: 0 auto;
  width: 600px;
  background: #192b54;
  border-radius: 20px;
  overflow: hidden;
}
.Invite-frm {
  border: 1px solid rgba(169, 182, 225, 1);
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  line-height: 30px;
  padding: 12px 35px;
}
.Invite-frm > div > div:first-child {
  /* width: 70px; */
  text-align: left;
}
.reg-frm > div {
  width: 240px;
  margin: 50px auto;
}
</style>